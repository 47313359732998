export const constant = {
    //---------- Local Server
   /*  baseUrl: 'http://127.0.0.1:5001/',
    officetypeId:'6719fe1c67ac50352ac3f166',
    companyTypeId:'6719fde367ac50352ac3f154',
    industryId:'6719fe1467ac50352ac3f163', */
    
    //---------- Live Server
    baseUrl: 'https://api.leadsbuzzer.com/',
    officetypeId:'671a135c078c1871c9ca5310',
    companyTypeId:'671a1331078c1871c9ca5304',
    industryId:'671a1349078c1871c9ca530a',
}
 
 